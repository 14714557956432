import { useEffect, useState } from "react";
import { useMeetingManager } from "../providers/MeetingProvider";

export const useTalkingStatus = () => {
  const meetingManager = useMeetingManager();
  const [talkingStatus, setTalkingStatus] = useState(
    () => meetingManager.talkingStatus
  );

  useEffect(() => {
    const callback = (updatedTalkingStatus) => {
      setTalkingStatus(updatedTalkingStatus);
    };
    meetingManager.subscribeToTalkingStatus(callback);

    return () => {
      meetingManager.unsubscribeFromTalkingStatus(callback);
    };
  }, []);

  return talkingStatus;
};

export default useTalkingStatus;
