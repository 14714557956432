import { EventAttributes, EventName } from "amazon-chime-sdk-js";
import { createContext, useContext, useEffect, useState } from "react";
import { useMeetingManager } from "../MeetingProvider";

type MeetingEventProviderContextType = {
      name: EventName;
      attributes: EventAttributes;
    }
  | undefined;

export const MeetingEventProviderContext = createContext<MeetingEventProviderContextType>(undefined);

const MeetingEventProvider = ({ children }) => {
  const [meetingEvent, setMeetingEvent] = useState<MeetingEventProviderContextType>();
  const meetingManager = useMeetingManager();

  useEffect(() => {
    function meetingEventUpdateCallback(name, attributes) {
      setMeetingEvent({ name, attributes });
    }

    meetingManager.subscribeToEventDidReceive(meetingEventUpdateCallback);

    return () => meetingManager.unsubscribeFromEventDidReceive(meetingEventUpdateCallback);
  }, []);


  return (
    <MeetingEventProviderContext.Provider value={meetingEvent}>
      {children}
    </MeetingEventProviderContext.Provider>
  );
};

const useMeetingEvent = () => {
  const meetingEvent = useContext(MeetingEventProviderContext);
  return meetingEvent;
};

export { useMeetingEvent, MeetingEventProvider };
