import { AudioVideoFacade } from "amazon-chime-sdk-js";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useMeetingManager } from "../MeetingProvider";

type AudioVideoValue = AudioVideoFacade | null;

export const AudioVideoContext = createContext<AudioVideoValue>(null);

const AudioVideoProvider = ({ children }) => {
  const meetingManager = useMeetingManager();
  const [audioVideo, setAudioVideo] = useState<AudioVideoValue>(null);

  useEffect(() => {
    function audioVideoUpdateCb(av) {
      setAudioVideo(av);
    }

    meetingManager.subscribeToAudioVideo(audioVideoUpdateCb);

    return () => meetingManager.unsubscribeFromAudioVideo(audioVideoUpdateCb);
  }, []);

  return (
    <AudioVideoContext.Provider value={audioVideo}>
      {children}
    </AudioVideoContext.Provider>
  );
};

const useAudioVideo = () => {
  const audioVideo = useContext(AudioVideoContext);

  return audioVideo;
};

export { useAudioVideo, AudioVideoProvider };
