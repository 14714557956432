import { FC, memo, Ref, useEffect, useRef } from "react"
import { Text, View } from "react-native"
import { useAudioVideo } from "../../../../providers/AudioVideoProvider"
import { useCustomerVideo } from "../../../../providers/CustomerVideoProvider"
import FaIcon from "../../../FaIcon"
import StyledButton from "../../../StyledButton"

type CustomerVideoProps = {
  portrait: boolean
}

const CustomerVideo: FC<CustomerVideoProps> = (props: CustomerVideoProps, ref: Ref<HTMLVideoElement>) => {
  const { tileId, isVideoEnabled, toggleVideo } = useCustomerVideo();
  const audioVideo = useAudioVideo();
  const videoEl = useRef(null);

  useEffect(() => {
    if (!audioVideo || !tileId || !videoEl.current || !isVideoEnabled) {
      return;
    }

    audioVideo.bindVideoElement(tileId, videoEl.current);

    return () => {
      const tile = audioVideo.getVideoTile(tileId);
      if (tile) {
        audioVideo.unbindVideoElement(tileId);
      }
    };
  }, [audioVideo, tileId, isVideoEnabled]);

  return (
    <View style={{position: 'relative', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
      <video
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        ref={videoEl}
      />
      <View
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          display: isVideoEnabled? 'none': 'flex'
        }}
      >
        <FaIcon icon="video-slash" color='#a9a9a9' size={100} style={{ position: 'relative', marginBottom: '50' }}></FaIcon>
      </View>
      <StyledButton
        title={isVideoEnabled ? "ビデオオフ(相手)" : "ビデオオン(相手)"}
        color="#6DBC89"
        icon={
          isVideoEnabled ?
            <FaIcon icon="video" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} /> :
            <FaIcon icon="video-slash" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />
        }
        onPress={toggleVideo}
        buttonStyle={{
          width: 'calc(100% - 20px)',
          display: props.portrait ? 'block' : 'none',
          position: props.portrait? 'absolute': 'static',
          bottom: 10,
        }}
      />
    </View>
  )
}

export default memo(CustomerVideo)