import axios from "axios";
import { API_HOST } from "../constants";

export const get = async <T>(url, token): Promise<T> => {
  try {
    const response = await axios.get(`${API_HOST}/api/v2/family${url}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return response.data.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const post = async <T>(url, token, data): Promise<T> => {
  try {
    const response = await axios.post(`${API_HOST}/api/v2/family${url}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return response.data.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
