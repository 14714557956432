import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';

/**
 * 装飾ラベル付きボタンコンポーネント
 */
export default function StyledButton(props) {
  const { onPress, title = 'Save', color, textStyle = {}, buttonStyle = {}, icon = <></>, center = false } = props;

  const styles = StyleSheet.create({
    textStyle: {
      ...{
        color: '#fff',
        alignContent: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 20
      },
      ...textStyle
    },
    buttonStyle: {
      ...{
        backgroundColor: color,
        padding: 10,
        borderRadius: 20,
        alignContent: 'center',
        textAlign: 'center'
      },
      ...buttonStyle
    }
  })

  return (
    <Pressable style={styles.buttonStyle} onPress={onPress}>
      {(() => {
        if (center) {
          return (
            <>
              <View style={{alignItems: 'center'}}>
                {icon}
              </View>
              <Text style={styles.textStyle}>{title}</Text>
            </>
          )
        } else {
          return (
            <>
              {icon}
              <Text style={styles.textStyle}>{title}</Text>
            </>
          )
        }
      })()}
    </Pressable>
  );
}
