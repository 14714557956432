import { FC, memo, Ref, useEffect, useRef } from "react"
import { Text, View } from "react-native"
import { useAudioVideo } from "../../../../providers/AudioVideoProvider"
import { useLocalVideo } from "../../../../providers/LocalVideoProvider"
import FaIcon from "../../../FaIcon"
import StyledButton from "../../../StyledButton"

type LocalVideoProps = {
  portrait: boolean
}

const LocalVideo: FC<LocalVideoProps> = (props: LocalVideoProps, ref: Ref<HTMLVideoElement>) => {
  const { tileId, isVideoEnabled, toggleVideo} = useLocalVideo();
  const audioVideo = useAudioVideo();
  const videoEl = useRef(null);

  useEffect(() => {
    if (!audioVideo || !tileId || !videoEl.current || !isVideoEnabled) {
      return;
    }

    audioVideo.bindVideoElement(tileId, videoEl.current);

    return () => {
      const tile = audioVideo.getVideoTile(tileId);
      if (tile) {
        audioVideo.unbindVideoElement(tileId);
      }
    };
  }, [audioVideo, tileId, isVideoEnabled]);

  return (
    <View style={{position: 'relative', width: '100%', height: '100%', alignItems: 'center'}}>
      <View style={{position: 'relative', width: '100%', height: '100%'}}>
        <video
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            border: props.portrait? 'none' : '1px solid #ccc',
            backgroundColor: '#fff',
            boxSizing: 'border-box'
          }}
          ref={videoEl}
        />
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            display: isVideoEnabled? 'none': 'flex'
          }}
        >
          <FaIcon icon="video-slash" color='#a9a9a9' size={100} style={{ position: 'relative' }}></FaIcon>
        </View>
      </View>
      <StyledButton
        title={(isVideoEnabled ? "ビデオオフ" : "ビデオオン") + (props.portrait? "(あなた)": "")}
        color="#6DBC89"
        icon={
          isVideoEnabled ?
            <FaIcon icon="video" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} /> :
            <FaIcon icon="video-slash" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />
        }
        onPress={toggleVideo}
        buttonStyle={{
          width: 'calc(100% - 20px)',
          display: 'block',
          position: props.portrait? 'absolute': 'static',
          bottom: 10,
          marginTop: props.portrait? '0px' : '10px'
        }}
      />
    </View>
  )
}

export default memo(LocalVideo)