import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Image, Modal, SafeAreaView, StyleSheet, Text, View, Dimensions } from 'react-native';
import { useNavigate, useParams } from 'react-router';
import StyledButton from '../components/StyledButton';
import { useAuth } from '../routes/route';
import { API_HOST, CAN_RECORDING, RECORDING_SLICE_SECONDS, SKYWAY_API_KEY } from '../constants';
import Peer, { DataConnection, MediaConnection } from 'skyway-js';
import FaIcon from '../components/FaIcon';
import RecordRTCPromisesHandler from 'recordrtc';
import dayjs from 'dayjs';
import { useNavigation } from '@react-navigation/native';
import { BROWSER, DEVICE, getBrowser, getMobileOS, isMobile } from '../utils/DeviceCheck';
import { usePortrait } from '../providers/PortraitProvider';

declare global {
  interface Window {
    timeoutInterval: null | NodeJS.Timer,
    checkScreenOrientationInterval: null | NodeJS.Timer,
    endTimeoutInterval: null | NodeJS.Timer
  }
}
window.timeoutInterval = null;
window.checkScreenOrientationInterval = null;
window.endTimeoutInterval = null;


// https://github.com/apache/cordova-plugin-camera/issues/673
export const Talking: FC<{}> = (props) => {

  const auth = useAuth();
  const {portrait} = usePortrait();
  const [peer, setPeer] = useState<Peer>();

  const [isVideoOff, setVideoOff] = useState(true);
  const [isRemoteVideoOff, setRemoteVideoOff] = useState(true);
  const [isMute, setIsMute] = useState(false);
  const [isEndConnection, setIsEndConnection] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [mediaConnectionClose, setMediaConnectionClose] = useState(false)
  const [isNetWorkError, setIsNetWorkError] = useState(false);
  const [isLocalStop, setIsLocalStop] = useState<boolean|null>(null);
  const [calling, setCalling] = useState(true);

  const params = useParams();
  const navigate = useNavigate()
  const localVideo = useRef<HTMLVideoElement>(null)
  const remoteVideo = useRef<HTMLVideoElement>(null)
  const [dataConnection, setDataConnection] = useState<DataConnection>();
  const [mediaConnection, setMediaConnection] = useState<MediaConnection>();

  const [customerName, setCustomerName] = useState('');
  const localRecorderRef = useRef<RecordRTCPromisesHandler>(null);
  const remoteRecorderRef = useRef<RecordRTCPromisesHandler>(null);

  const [removePage, setRemovePage] = useState(false);
  const [recCustomerStatus, setRecCustomerStatus] = useState('NO_RECORDING');
  const [recFamilyStatus, setRecFamilyStatus] = useState('NO_RECORDING');
  const [startTimeout, setStartTimeout] = useState(false);

  const navigation = useNavigation();

  let localRecordCount = 1;
  let remoteRecordCount = 1;
  const disconnected = useRef(true);
  const MAX_TIMEOUT_COUNT = 25;
  const MAX_END_TIMEOUT_COUNT = 20;
  let familyName = '';
  let familyNameKana = '';
  let iOS = isMobile() && getMobileOS() == DEVICE.IOS;
  let safari = getBrowser() == BROWSER.SAFARI;
  let RemoteVideoRecNoExec = iOS || safari;

  useEffect(() => {
    if (!auth) {
      return;
    }

    if (!navigator.mediaDevices) {
      peer?.destroy();
      alert('カメラにアクセスできません');
      pageRemove();
      return;
    }

    // // Peer セッションを生成
    setPeer(new Peer({
      key: SKYWAY_API_KEY ?? 'undefined',
      host: 'carebee.hubbit.io',
      debug: 1,
    }))
  }, [auth])

  useEffect(() => {
    // タイムアウトはモーダルで画面遷移する
    if (!isTimeout) {
      let recFamilyStatusTmp = recCustomerStatus;

      // IOSの場合のみFINISHとして処理をする
      if (RemoteVideoRecNoExec) {
        recFamilyStatusTmp = "FINISHED"
      }

      // ページ遷移時にレコード中だった場合は停止する
      if (removePage && (recFamilyStatus == "RECORDING" || recFamilyStatusTmp == "RECORDING")) {
        if(recCustomerStatus == 'RECORDING') {
          setRecCustomerStatus('FINISHING');
          remoteRecorderRef.current?.stopRecording();
        }

        if(recFamilyStatus == 'RECORDING') {
          setRecFamilyStatus('FINISHING');
          localRecorderRef.current?.stopRecording();
        }
      } else if (removePage && recFamilyStatus == "NO_RECORDING" && recFamilyStatus == "NO_RECORDING") {
        pageRemove();
      } else {
        if(removePage && (
          (recFamilyStatus == "FINISHED" && recFamilyStatusTmp == "FINISHED") ||
          (recFamilyStatus == "NO_RECORDING" && recFamilyStatusTmp == "FINISHED") ||
          (recFamilyStatus == "FINISHED" && recFamilyStatusTmp == "NO_RECORDING") ||
          (isNetWorkError && recFamilyStatus == "FINISHING" && recFamilyStatus == "FINISHING")
        )) {
          fetch(`${API_HOST}/api/v1/family/rec_upload_ended`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + auth.authToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: params.id, })
          }).then(r => {
          }).finally(() => {
            pageRemove();
          });
        }
      }
    }
  }, [removePage, recCustomerStatus, recFamilyStatus, isNetWorkError, isTimeout])

  useEffect(() => {
    // MediaConnectionがクローズされていて、かつコネクションが正常終了(終了ボタンを押した場合か相手から終了が送られてきた場合)ではない場合、
    // ネットワークエラーなので終了処理を実施する
    if (mediaConnectionClose && !isEndConnection) {
      setIsNetWorkError(true);
      setIsEndConnection(true);

      sendEndVideoChat(false, true);
    }
  }, [mediaConnectionClose, isEndConnection, recCustomerStatus, recFamilyStatus])

  useEffect(() => {
    if (localVideo.current?.srcObject) {
      (localVideo.current!.srcObject as MediaStream).getVideoTracks()[0].enabled = !isVideoOff;
    }
  }, [isVideoOff])

  useEffect(() => {
    if (dataConnection) {
      const command = `video ${isRemoteVideoOff ? 'off' : 'on'}`
      if (dataConnection.open) {
        dataConnection.send(command);
      }  
    }
  }, [isRemoteVideoOff, dataConnection])

  const sendBlobFile = async function (blob, target) {
    let finish = false;

    const count = target === 'family' ? localRecordCount : remoteRecordCount;
    const recorder = target === 'family' ? localRecorderRef.current : remoteRecorderRef.current;
    const callHistoryId = params.id;

    const fileName = `${dayjs().format('YYYYMMDDHHmmss')}_${callHistoryId}_${target}_` + String(count).padStart(3, '0');
    const formData = new FormData();
    formData.append("video_filename", fileName);
    formData.append("video_blob", blob);
    formData.append("count", String(count));
    formData.append("call_history_id", callHistoryId!)
    formData.append("rec_target", target);

    if (recorder?.getState() === 'stopped') {
      formData.append("finish", String(true));
      finish = true;
    }

    if (!CAN_RECORDING) {
      console.log('送信データ', formData);
      return;
    }

    fetch(`${API_HOST}/api/v1/family/upload_video_chat`, {
      method: 'post',
      headers: {
        'Authorization': 'Bearer ' + auth.authToken,
      },
      body: formData
    })
      .then(response => {
        if (!response.ok) {
          console.error(target, 'サーバーエラー');
        }
        // ここに成功時の処理を記述
        console.info(target, 'ファイルの転送に成功しました。')
      })
      .catch(error => {
        console.error(target, 'ファイルの転送に失敗しました', error);
      }).finally(() => {
        // finish時は成功失敗に関わらず録画完了とする
        if (finish) {
          if (target == 'family') {
            setRecFamilyStatus("FINISHED");
            localRecorderRef.current = null;
          } else {
            setRecCustomerStatus("FINISHED");
            remoteRecorderRef.current = null;
          }
        }
      });
  }

  useEffect(() => {
    if (!peer) {
      return;
    }

    peer.once('open', () => {
      console.log('open:', peer);

      const camera  =  "camera"  as  PermissionName ;
      const microphone  =  "microphone"  as  PermissionName ;

      let cameraPermission = "noStatus"
      let microphonePermission = "noStatus"
      if ("permissions" in navigator) {
        navigator.permissions.query({ name: camera }).then((result) => {
          cameraPermission = result.state;
        })
        navigator.permissions.query({ name: microphone }).then((result) => {
          microphonePermission = result.state;
        })
      }

      if(cameraPermission != "denied" && microphonePermission != "denied") {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
          // skyway_id を利用者に通知
          fetch(`${API_HOST}/api/v1/family/update_skyway_id`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + auth.authToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              call_history_id: params.id,
              skyway_id: peer?.id
            })
          }).then(r => {
            if (!r.ok) {
              // 何かしらエラーが発生しているので前に戻る
              console.error('open:', r.body);
              setIsEndConnection(true);
              sendEndVideoChat(true, true);
              pageRemove();
              return;
            }

            r.json().then(data => {
              // タイムアウトカウントダウン開始
              setStartTimeout(true);

              setCustomerName(`${data.data.customer.last_name} ${data.data.customer.first_name}`)
              familyName = data.data.family.full_name
              familyNameKana = data.data.family.full_name_kana;
              (localVideo.current! as HTMLVideoElement).srcObject = stream;
                (localVideo.current!.srcObject as MediaStream).getVideoTracks()[0].enabled = !isVideoOff;

                localRecorderRef.current = new RecordRTCPromisesHandler(stream, {
                  type: "video",
                  disableLogs: true,
                  timeSlice: 1000 * RECORDING_SLICE_SECONDS,
                  ondataavailable: async function (blob) {
                    // setLocalRecordCount(localRecordCount + 1);
                    await sendBlobFile(blob, 'family');
                    localRecordCount++;
                    console.log('callback', localRecorderRef.current);
                  },
                });
            })
          });
        }).catch(err => {
          if ("permissions" in navigator) {
            navigator.permissions.query({ name: camera }).then((result) => {
              cameraPermission = result.state;
            })
            navigator.permissions.query({ name: microphone }).then((result) => {
              microphonePermission = result.state;
            })
          }
          let target = ""
          if(cameraPermission == microphonePermission && cameraPermission == "denied") {
            target = "カメラとマイク"
          }else if(cameraPermission == "denied") {
            target = "カメラ"
          }else{
            target = "マイク"
          }
          alert(`${target}の許可がないため通話を開始できません。`)

          setIsEndConnection(true);
          sendEndVideoChat(true, false);
          pageRemove();
          return;
        });
      }else{
        let target = ""
        if(cameraPermission == microphonePermission) {
          target = "カメラとマイク"
        }else if(cameraPermission == "denied") {
          target = "カメラ"
        }else{
          target = "マイク"
        }
        alert(`${target}の許可がないため通話を開始できません。`)

        setIsEndConnection(true);
        sendEndVideoChat(true, false);

        pageRemove();
        return;
      }

    });

    peer.once("connection", (dataConnection) => {
      setDataConnection(dataConnection);

      dataConnection.once('data', data => {
        if (data == "ready ok") {
          dataConnection.send(familyName + "(" + familyNameKana + ")")
        } else if (data == "webVideoChat close") {
          setIsEndConnection(true);
          if (disconnected.current) sendEndVideoChat(false);
        }
      });

      dataConnection.once('close', () => {
        if(!isEndConnection) {
          setIsEndConnection(true);
          if (disconnected.current) sendEndVideoChat(false);
        }
      })
    });
  
    peer.once('call', mediaConnection => {
      console.log('call:', mediaConnection);

      // タイムアウト処理をキャンセル
      if(window.timeoutInterval) {
        clearInterval(window.timeoutInterval);
        window.timeoutInterval = null;
      }

      mediaConnection.answer((localVideo.current! as HTMLVideoElement).srcObject as MediaStream)
      localVideo.current?.play();

      mediaConnection.on('stream', async stream => {
        (remoteVideo.current! as HTMLVideoElement).srcObject = stream;
        remoteVideo.current?.play();

        if (!RemoteVideoRecNoExec) {
          remoteRecorderRef.current = new RecordRTCPromisesHandler(stream, {
            type: "video",
            disableLogs: true,
            timeSlice: 1000 * RECORDING_SLICE_SECONDS,
            ondataavailable: async function (blob) {
              await sendBlobFile(blob, 'customer');
              remoteRecordCount++;
            },
          });
          remoteRecorderRef.current.startRecording();
          setRecCustomerStatus("RECORDING");

          localRecorderRef.current.startRecording();
          setRecFamilyStatus("RECORDING");    
        }
      });

      mediaConnection.once('close', () => {
        // リモートが閉じられたら終話
        console.log('mediaConnection close');
        setMediaConnectionClose(true);
        startEndTimeoutInterval();

        closePeer();
      });

      setMediaConnection(mediaConnection);

      // ビデオチャットの開始を通知
      fetch(`${API_HOST}/api/v1/family/start_video_chat`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + auth.authToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: params.id
        })
      }).then(r => {
        if (r.ok) {
          setCalling(false);
        }
        else {
          // 何かしらエラーが発生しているので前に戻る
          console.error('api call:', r.body);
          sendEndVideoChat(true, true);
        }
      });
    });

    peer.once('close', () => {
      console.info("close", peer?.id);

      if(window.timeoutInterval) {
        clearInterval(window.timeoutInterval);
        window.timeoutInterval = null;
      }
    
      if (peer?.open) {
        // キャンセルも終話も押されていないときに実行？
        if (disconnected.current) {
          console.warn("disconnected", peer?.id);

          // 終了通知
          fetch(`${API_HOST}/api/v1/family/disconnect_video_chat`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + auth.authToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id: params.id
            })
          }).then(r => {
            console.info('api disconnect', r.body)            
          }).finally(() => {
            pageRemove();
          });
        } else {
          setRemovePage(true);
        }
      } else {
        pageRemove();
      }
    });

    peer.on('error', (error) => {
      console.error('error', error);
      if(window.timeoutInterval) {
        clearInterval(window.timeoutInterval);
        window.timeoutInterval = null;
      }
    
      disconnected.current = false;
      closePeer();

      // エラー通知(送信のみ)
      fetch(`${API_HOST}/api/v1/family/error_video_chat`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + auth.authToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: params.id
        })
      }).then(r => {
        console.info('api error', r.body);
      }).finally(() => {
        pageRemove();
      });
    });
  }, [peer])

  useEffect(function() {
    if (!window.timeoutInterval && !isTimeout) {
      let timeoutCount = 0;

      window.timeoutInterval = setInterval(() =>{
        let nextTimeoutCount = timeoutCount + 1;
  
        if (nextTimeoutCount > MAX_TIMEOUT_COUNT) {
          if (window.timeoutInterval) {
            clearInterval(window.timeoutInterval)
            window.timeoutInterval = null;
          };
          setIsTimeout(true);
          disconnected.current = false;
          closePeer();
  
          fetch(`${API_HOST}/api/v1/family/timeout_video_chat`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + auth.authToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: params.id, })
          }).then(r => {
            console.info('api end', r.body);
          });
        } else {
          timeoutCount = nextTimeoutCount
        }      
      }, 1000);
    }

    return function(){
      if (window.timeoutInterval) {
        clearInterval(window.timeoutInterval)
        window.timeoutInterval = null;
      };
    };
  }, [startTimeout, isTimeout]);

  // useEffect(function() {
  //   if (!window.checkScreenOrientationInterval) {
  //     let timeCount = 0;
  //     let lastTime = isPortrait();
  //     // 連続回数
  //     let consecutiveTimes = 0;
  
  //     window.checkScreenOrientationInterval = setInterval(() =>{
  //       timeCount++;
  //       let current = isPortrait();
  //       if(current == lastTime) {
  //         consecutiveTimes++
  //         if(consecutiveTimes >= 4) {
  //           setPortrait(current);
  //           consecutiveTimes = 0;
  //         }
  //       }else{
  //         consecutiveTimes = 0;
  //         lastTime = current
  //       }
  //     }, 200);
  //   }

  //   return function(){
  //     if (window.checkScreenOrientationInterval) {
  //       clearInterval(window.checkScreenOrientationInterval)
  //       window.checkScreenOrientationInterval = null;
  //     };
  //   };
  // }, [portrait]);

  const sendCancel = () => {
    // キャンセルを送信
    fetch(`${API_HOST}/api/v1/family/stop_video_chat`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + auth.authToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: params.id, })
    }).then(r => {
      console.info('api stop', r.body);
      disconnected.current = false;
      closePeer(true, true);
    });
  }

  const sendVideoChatCloseMessageToRemote = () => {
    if (dataConnection?.open) {
      dataConnection?.send('webVideoChat close');
    }
  }

  const sendEndVideoChat = (closeLocal=true, networkError=false) => {
    const postUrl = networkError? `${API_HOST}/api/v1/family/network_error_end`: `${API_HOST}/api/v1/family/end_video_chat`;

    fetch(postUrl, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + auth.authToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: params.id })
    }).then(r => {
      console.info('api end', r.body);
    }).finally(() => {
      disconnected.current = false;
      
      // localからクローズしている場合またはネットワークエラーの場合はpeerのクローズ処理を実行
      if (closeLocal || networkError) {
        closePeer();
      }
    });
  }

  const pageRemove = () => {
    // ページ遷移はしないがゴミが残っていた場合は全てクローズする
    if (remoteRecorderRef.current) {
      remoteRecorderRef.current.stopRecording();
    }
    if (localRecorderRef.current) {
      localRecorderRef.current.stopRecording();
    }

    closePeer(false);

    clearEndTimeoutInterval();
    navigation.setOptions({
      tabBarStyle: { display: 'flex' }
    })
    navigate('/app/address')
  }

  const onPressEndButton = () => {
    setIsLocalStop(true);
    setIsEndConnection(true);
    sendVideoChatCloseMessageToRemote();
    sendEndVideoChat();
  }

  const onPressCancelButton = () => {
    sendCancel();
  }

  const closePeer = (removePage=true, forceRemovePage=false) => {
    if (dataConnection && dataConnection.open) {
      dataConnection.close();
    }

    if (localVideo.current?.srcObject) {
      (localVideo.current.srcObject as MediaStream).getTracks().forEach(track => {
        track.stop();
      });
      (localVideo.current! as HTMLVideoElement).srcObject = null;
      console.log("ローカルビデオストップ")
    }
    if (remoteVideo.current?.srcObject) {
      (remoteVideo.current.srcObject as MediaStream).getTracks().forEach(track => {
        track.stop();
      });
      (remoteVideo.current! as HTMLVideoElement).pause();
      (remoteVideo.current! as HTMLVideoElement).srcObject = null;
      console.log("リモートビデオストップ")
    }

    if (mediaConnection && mediaConnection.close) {
      // mediaConnectionをクローズする場合は再度このメソッドがmediaConnectionのクローズイベントで呼ばれる
      mediaConnection.close();
    }
    
    if (peer && peer.open) {
      peer.destroy()
    }

    if (removePage) {
      if (forceRemovePage) {
        pageRemove();
      } else {
        setRemovePage(true);
      }  
    }
  }

  const startEndTimeoutInterval = () => {
    let timeoutCount = 0;

    window.endTimeoutInterval = setInterval(() =>{
      let nextTimeoutCount = timeoutCount + 1;

      if (nextTimeoutCount > MAX_END_TIMEOUT_COUNT) {
        if (window.endTimeoutInterval) {
          clearInterval(window.endTimeoutInterval)
          window.endTimeoutInterval = null;
        };

        pageRemove();
      } else {
        timeoutCount = nextTimeoutCount
      }      
    }, 1000);
  }

  const clearEndTimeoutInterval = () => {
    // タイムアウト処理をキャンセル
    if(window.endTimeoutInterval) {
      clearInterval(window.endTimeoutInterval);
      window.endTimeoutInterval = null;
    }    
  }

  return (
    <SafeAreaView onLayout={useCallback(() => {
      console.log('Talking', portrait)
    }, [])} style={{ flex: 1 }}>

      {/* portrait パラメータを見て縦横切り替わる */}
      <View style={{ height: 'calc(100% - 70px)', position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <View style={portrait ? { display: 'flex', height: '50%' } : {}}>
          <video style={{ objectFit: 'cover' }} ref={remoteVideo} width={'100%'} height={'100%'} playsInline={true}></video>
          <View style={{ position: 'absolute', right: 0, left: 0, bottom: 10, alignItems: 'center', zIndex: 1000 }}>
            <div style={{ display: 'flex' }}>
              <StyledButton
                title={
                  (isRemoteVideoOff)
                    ? <div style={{ whiteSpace: 'nowrap', paddingRight: 5, paddingLeft: 5 }}><FaIcon icon="video" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />ビデオオン(相手)</div>
                    : <div style={{ whiteSpace: 'nowrap', paddingRight: 5, paddingLeft: 5 }}><FaIcon icon="video-slash" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />ビデオオフ(相手)</div>
                }
                color="#6DBC89"
                onPress={() => {
                  setRemoteVideoOff(!isRemoteVideoOff);
                }}
                buttonStyle={{ width: 'wrapContent', display: portrait ? 'block': 'none' }} />
            </div>
          </View>
        </View>
        <View style={portrait ? { height: '50%' } : { position: 'absolute', right: 10, top: 10, width: 180 }}>
          <video style={portrait ? { objectFit: 'cover' } : { objectFit: 'cover', border: '1px solid #ccc' }} ref={localVideo} width={'100%'} height={'100%'} muted={true} playsInline={true}></video>
          <View style={portrait ? { position: 'absolute', right: 0, left: 0, bottom: 10, alignItems: 'center', zIndex: 1000 } : {}}>
            <div style={{ display: 'flex', flexWrap: portrait ? 'nowrap' : 'wrap', justifyContent: 'center' }}>
              <StyledButton
                title={
                  (isVideoOff)
                    ? <div style={{ whiteSpace: 'nowrap', paddingRight: 5, paddingLeft: 5 }}><FaIcon icon="video" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />{ portrait? 'ビデオオン (あなた)': 'ビデオオン'}</div>
                    : <div style={{ whiteSpace: 'nowrap', paddingRight: 5, paddingLeft: 5 }}><FaIcon icon="video-slash" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />{ portrait? 'ビデオオフ (あなた)': 'ビデオオフ'}</div>
                }
                color="#6DBC89"
                onPress={() => {
                  setVideoOff(!isVideoOff);
                }}
                buttonStyle={{ width: 'wrapContent', marginTop: '10px' }} />
              <div style={{ display: 'none' }}>
                <StyledButton
                  title={
                    (isMute)
                      ? <div><FaIcon icon="microphone" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />マイクオン</div>
                      : <div><FaIcon icon="microphone-slash" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />マイクオフ</div>
                  }
                  color="#6DBC89"
                  onPress={() => {
                    setIsMute(!isMute);
                    if (localVideo.current?.srcObject) {
                      (localVideo.current.srcObject as MediaStream).getAudioTracks()[0].enabled = isMute;
                    }
                  }}
                  buttonStyle={{ marginLeft: portrait ? 20 : 0, width: '140px', marginTop: '10px' }} />
              </div>
            </div>
          </View>
        </View>
      </View>
      <View style={{ position: 'absolute', left: 0, right: 0, bottom: 10, display: 'flex', justifyContent: portrait ? 'center' : 'flex-start', flexDirection: 'row' }}>
        <StyledButton
          title={<div><FaIcon icon="times" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />通話終了</div>}
          color="#DB0000"
          onPress={() => { onPressEndButton() }}
          buttonStyle={portrait ? { width: '60%', marginBottom: '0px' } : { marginHorizontal: 20, width: 240 }} />

        <StyledButton
          title={
            (isRemoteVideoOff)
              ? <div style={{whiteSpace: 'nowrap', paddingRight: 5, paddingLeft: 5}}><FaIcon icon="video" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />ビデオオン</div>
              : <div style={{whiteSpace: 'nowrap', paddingRight: 5, paddingLeft: 5}}><FaIcon icon="video-slash" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />ビデオオフ</div>
          }
          color="#6DBC89"
          onPress={() => {
            setRemoteVideoOff(!isRemoteVideoOff);
            const command = `video ${isRemoteVideoOff ? 'on' : 'off'}`
            if (dataConnection?.open) {
              dataConnection?.send(command);
            }
          }}
          buttonStyle={{ width: 'wrapContent', display: portrait ? 'none' : 'block' }} />
      </View>

      {/* 発信中モーダル */}


      {/* 通話終了中モーダル */}
      <Modal
        animationType="none"
        transparent={false}
        visible={isEndConnection}>

        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View>
              <View style={{ alignContent: 'center', alignItems: 'center', marginLeft: '20px' }}>
                <Text style={{ marginTop: '1em' }}>{(isNetWorkError? "ネットワークエラーのため": "") + "通話終了中..."}</Text>
                <Text style={{ textAlign: 'center', marginVertical: 30 }}>画面が切り替わるまでしばらくお待ち下さい。</Text>
              </View>
            </View>
          </View>
        </View>
      </Modal>

      {/* 通話終了中モーダル */}
      <Modal
        animationType="none"
        transparent={false}
        visible={isTimeout}>

        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View>
              <View style={{ alignContent: 'center', alignItems: 'center', marginLeft: '20px' }}>
                <Text style={{ marginTop: '1em' }}>{customerName}さんに接続できませんでした。</Text>
                <Text style={{ textAlign: 'center' }}>タブレットの電源が切れているか</Text>
                <Text style={{ textAlign: 'center' }}>Carebeeが起動されてません。</Text>
                <Text style={{ textAlign: 'center', marginBottom: 30 }}>時間をあけてから再度おかけください。</Text>
              </View>
            </View>
            <StyledButton
              title={<div>通話終了</div>}
              buttonStyle={{ width: '15em' }}
              color={'#DB0000'}
              onPress={() => pageRemove()} />
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  profileImgContainer: {
    marginLeft: 8,
    height: 82,
    width: 82,
    borderRadius: 40,
    borderWidth: 1,
    backgroundColor: '#fff',
    marginRight: 20,
  },
  profileImg: {
    height: 80,
    width: 80,
    borderRadius: 40,
  },
});
