import React, { createContext, useContext, useCallback, useMemo } from "react";
import { useAudioVideo } from "../AudioVideoProvider";
import { State } from "./state";

const CahtContext = createContext<State | null>(null);

const ChatProvider = ({ children }) => {
  const audioVideo = useAudioVideo();

  const sendVideoOnOffChatMessage = useCallback(
    async (currentIsVideoEnabled) => {
      console.log("send message")
      if (!audioVideo) {
        return
      }

      // チャット送信処理
      const topic = "backendmessage";
      const message = currentIsVideoEnabled ? "video off" : "video on";

      audioVideo.realtimeSendDataMessage(topic, message, 1000);
    },
    [audioVideo]
  );

  const value = useMemo(
    () => ({
      sendVideoOnOffChatMessage,
    }),
    [sendVideoOnOffChatMessage]
  );

  return <CahtContext.Provider value={value}>{children}</CahtContext.Provider>;
};

const useChat = () => {
  const context = useContext(CahtContext);

  if (!context) {
    throw new Error("CahtContext must be used within ChatProvider");
  }

  return context;
};
export { ChatProvider, useChat };
