import { useEffect, useState } from "react";

import { useMeetingManager } from "../providers/MeetingProvider";

export const useMeetingStatus = () => {
  const meetingManager = useMeetingManager();
  const [meetingStatus, setMeetingStatus] = useState(
    () => meetingManager.meetingStatus
  );

  useEffect(() => {
    const callback = (updatedMeetingStatus) => {
      setMeetingStatus(updatedMeetingStatus);
    };
    meetingManager.subscribeToMeetingStatus(callback);

    return () => {
      meetingManager.unsubscribeFromMeetingStatus(callback);
    };
  }, []);

  return meetingStatus;
};

export default useMeetingStatus;
