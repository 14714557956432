import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Roster } from '../../types/Roster';

import { useAudioVideo } from '../AudioVideoProvider';
import { useMeetingManager } from '../MeetingProvider';

interface RosterContextValue {
  roster: string[];
}

const RosterContext = React.createContext<RosterContextValue | null>(null);

const RosterProvider: React.FC = ({ children }) => {
  const meetingManager = useMeetingManager();
  const audioVideo = useAudioVideo();
  const [roster, setRoster] = useState<string[]>([]);

  useEffect(() => {
    if (!meetingManager) return;

    let onlyMeTimeout = 0;
    const MAX_ONLY_ME_TIMEOUT = 30;

    const interval = setInterval(() => {
      if (roster.length >= 2) {
        onlyMeTimeout = 0;
      }

      onlyMeTimeout++;

      if (onlyMeTimeout >= MAX_ONLY_ME_TIMEOUT) {
        meetingManager.onRosterIsOnlyMe();
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [roster, meetingManager]);
  
  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    let newRoster = [...roster];

    const rosterUpdateCallback = async (
      chimeAttendeeId,
      present,
      externalUserId,
      dropped
    ) => {
      if (!externalUserId.match(/MediaPipeline/)) {
        if (!newRoster.includes(chimeAttendeeId) && dropped == false) {
          meetingManager.onJoinedAttendee(chimeAttendeeId);
          newRoster.push(chimeAttendeeId);
        }

        if (newRoster.includes(chimeAttendeeId) && dropped == true) {
          newRoster = newRoster.filter(
            (attendeeId) => attendeeId != chimeAttendeeId
          );
        }

        setRoster(newRoster);
      }
    };

    audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

    return () => {
      audioVideo.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdateCallback);
    };
  }, [audioVideo, roster, setRoster]);

  const value = useMemo(
    () => ({
      roster,
    }),
    [roster]
  );

  return (
    <RosterContext.Provider value={value}>{children}</RosterContext.Provider>
  );
};

function useRosterState(): RosterContextValue {
  const state = useContext(RosterContext);

  if (!state) {
    throw new Error('userRosterState must be used within RosterProvider');
  }

  return state;
}

export { RosterProvider, useRosterState };
