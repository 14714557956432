import React, { FC, useCallback, useState } from 'react';
import { Dimensions, Image, StyleSheet, View } from 'react-native';
import { usePortrait } from '../providers/PortraitProvider';
import { isMobile } from '../utils/DeviceCheck';

/**
 * 共通ヘッダコンポーネント
 */
export const Header: FC<{}> = (props) => {

  const {portrait} = usePortrait()
  console.log(portrait)

  return (
    <View>
      {(() => {
        if (portrait || !isMobile()) {
          return (
            <View style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingVertical: 20,
            }}>
              <Image
                style={Object.assign(styles.logo, { width: 200, height: 50 })}
                source={require('../logo.png')}
              />
            </View>
          )
        }
      })()}
    </View>
  )
}

const styles = StyleSheet.create({
  logo: {
    width: Dimensions.get('window').width,
    resizeMode: "contain",
    maxWidth: '100%'
  }
});
