import { useEffect, useState } from "react";

import { useMeetingManager } from "../providers/MeetingProvider";

export const useCustomer = () => {
  const meetingManager = useMeetingManager();
  const [customer, setCustomer] = useState(() => meetingManager.customer);

  useEffect(() => {
    const callback = (updatedCustomer) => {
      setCustomer(updatedCustomer);
    };
    meetingManager.subscribeToCustomer(callback);

    return () => {
      meetingManager.unsubscribeFromCustomer(callback);
    };
  }, []);

  return customer;
};

export default useCustomer;
