import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { Attendee } from "../types/Attendee";
import { Meeting } from "../types/Meeting";
import { get, post } from "./ApiV2Base";

export type StartTalkingRespose = {
  meeting: {
    MeetingId: string,
    ExternalMeetingId: string
  }
}

export type JoinTalkingRespose = {
  meeting: Meeting,
  attendee: Attendee
}

export type JoinedTalkingRespose = {}
export type leavedTalkingRespose = {}
export type EndTalkingRespose = {}
export const ErrorTalkingErrorType = {
  CANCEL: "CANCEL",
  TIMEOUT: "TIMEOUT",
  NETWORK_ERROR: "NETWORK_ERROR",
  ERROR: "ERROR",
} as const;
 
export type ErrorTalkingErrorType = typeof ErrorTalkingErrorType[keyof typeof ErrorTalkingErrorType];

export async function startTalking(token): Promise<StartTalkingRespose> {
  const data = {};
  let response = await post<StartTalkingRespose>("/start_talking", token, data)
  return response;
}

export async function joinTalking(token, externalMeetingId): Promise<JoinTalkingRespose> {
  const data = {
    externalMeetingId: externalMeetingId,
  };
  return await post<JoinTalkingRespose>("/join_talking", token, data);
}

export async function joinedTalking(token, externalMeetingId): Promise<EndTalkingRespose> {
  const data = {
    externalMeetingId: externalMeetingId,
  };
  return await post<JoinedTalkingRespose>("/joined_talking", token, data);
}

export async function leavedTalking(token, externalMeetingId): Promise<EndTalkingRespose> {
  const data = {
    externalMeetingId: externalMeetingId,
  };
  return await post<leavedTalkingRespose>("/leaved_talking", token, data);
}

export async function endTalking(token, externalMeetingId): Promise<EndTalkingRespose> {
  const data = {
    externalMeetingId: externalMeetingId,
  };
  return await post<EndTalkingRespose>("/end_talking", token, data);
}

export async function errorTalking(token, externalMeetingId, error: ErrorTalkingErrorType, detail: string = ""): Promise<EndTalkingRespose> {
  const data = {
    externalMeetingId: externalMeetingId,
    error: error,
    detail: detail
  };
  return await post<EndTalkingRespose>("/error_talking", token, data);
}

