import { AudioVideoFacade, AudioVideoObserver, ConsoleLogger, DefaultDeviceController, DefaultEventController, DefaultMeetingSession, EventAttributes, EventController, EventName, EventObserver, LogLevel, MeetingSessionConfiguration, MeetingSessionStatusCode, NoOpDebugLogger } from "amazon-chime-sdk-js";
import { endTalking, errorTalking, ErrorTalkingErrorType, joinedTalking, joinTalking, leavedTalking, startTalking } from "../../api/TalkingApi";
import { Attendee } from "../../types/Attendee";
import { Customer } from "../../types/Customer";
import { Meeting } from "../../types/Meeting";
import { MeetingStatus } from "../../types/MeetingStatus";
import { TalkingStatus } from "../../types/TalkingStatus";

export class MeetingManager implements AudioVideoObserver {
  token: string | null = null;
  externalMeetingId: string | null = null;
  customer: Customer | null = null;
  audioVideo: AudioVideoFacade | null = null;
  meetingStatus: MeetingStatus = MeetingStatus.Init;
  talkingStatus: TalkingStatus = TalkingStatus.Init;
  meetingSessionConfiguration: MeetingSessionConfiguration | undefined = undefined;
  meeting: Meeting | null = null;
  myAttendee: Attendee | null = null;
  customerObservers: ((customer: Customer | null) => void)[] = [];
  meetingStatusObservers: ((meetingStatus: MeetingStatus) => void)[] = [];
  talkingStatusObservers: ((talkingStatus: TalkingStatus) => void)[] = [];
  audioVideoObserver: AudioVideoObserver = {};
  audioVideoCallbacks: ((audioVideo: AudioVideoFacade | null) => void)[] = [];
  videoDownlinkBandwidthPolicy = null;
  videoUplinkBandwidthPolicy = null;
  logger = null;
  logLevel = LogLevel.WARN;
  meetingSession: DefaultMeetingSession | null = null;
  meetingEventObserverSet = new Set<((name: EventName, attributes: EventAttributes) => void)>();
  showAddressPage: () => void = () => {};
  eventDidReceiveRef: EventObserver;

  constructor(token: string, showAddressPage: () => void, logLevel = LogLevel.WARN) {
    this.token = token;
    this.logLevel = logLevel;
    this.showAddressPage = showAddressPage;
    this.eventDidReceiveRef = {
      eventDidReceive: (name: EventName, attributes: EventAttributes) => {
        this.publishEventDidReceiveUpdate(name, attributes);
      },
    };
  }

  initializeMeetingManager() {
    this.meetingSession = null;
    this.audioVideo = null;
    this.meetingSessionConfiguration = undefined;
    this.externalMeetingId = null;
    this.audioVideoObserver = {};
    this.customer = null;
    this.myAttendee = null;
  }

  audioVideoDidStartConnecting?(reconnecting: boolean) {console.log("audioVideoDidStartConnecting")}

  async startMeeting() {
    return await this.startTalkingToServer();
  }

  async joinMeeting() {
    this.updateMeetingStatus(MeetingStatus.Ready);

    // 開始時にデバイスを確認
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      this.updateMeetingStatus(MeetingStatus.Error);
      console.log("enumerateDevices() not supported.");
      return;
    }

    // サーバーからトーク情報を取得
    await this.getTalkingInformation();

    this.updateTalkingStatus(TalkingStatus.Joining);

    this.meetingSessionConfiguration = new MeetingSessionConfiguration(
      this.meeting,
      this.myAttendee
    );

    let eventController = new DefaultEventController(
      new MeetingSessionConfiguration(this.meeting, this.myAttendee), 
      new NoOpDebugLogger(),
    );

    await this.initializeMeetingSession(this.meetingSessionConfiguration, eventController);
  }

  async start() {
    this.audioVideo?.start();
    this.audioVideo?.startLocalVideoTile();
  }

  async joinedTalking() {
    try {
      await joinedTalking(this.token, this.externalMeetingId);
      this.updateTalkingStatus(TalkingStatus.Joined);
    } catch (e) {
      console.log(e);
    }
  }

  async cancelMeeting() {
    this.endBase(TalkingStatus.Cancel);
  }

  async timeoutMeeting() {
    this.endBase(TalkingStatus.Timeout);
  }

  async end() {
    if (
      this.talkingStatus != TalkingStatus.Talking &&
      ![MeetingStatus.Ending, MeetingStatus.Ended].some(
        (meetingStatus) => meetingStatus == this.meetingStatus
      )
    ) {
      this.cancelMeeting();
    } else {
      this.endBase();
    }
  }

  async left() {
    this.endBase(TalkingStatus.Left);
  }

  async networkErrorEnd() {
    this.endBase(TalkingStatus.NetworkError);
  }

  async errorEnd() {
    this.endBase(TalkingStatus.Error);
  }

  private async endBase(talkingStatus: TalkingStatus = TalkingStatus.End) {
    this.updateTalkingStatus(talkingStatus);
    this.updateMeetingStatus(MeetingStatus.Ending);

    if (this.externalMeetingId) {
      try {
        switch (talkingStatus) {
          case TalkingStatus.Error:
            await errorTalking(this.token, this.externalMeetingId, ErrorTalkingErrorType.ERROR);
            break;
          case TalkingStatus.NetworkError:
            await errorTalking(this.token, this.externalMeetingId, ErrorTalkingErrorType.NETWORK_ERROR);
            break;
          case TalkingStatus.Timeout:
            await errorTalking(this.token, this.externalMeetingId, ErrorTalkingErrorType.TIMEOUT);
            break;
          case TalkingStatus.Cancel:
            await errorTalking(this.token, this.externalMeetingId, ErrorTalkingErrorType.CANCEL);
            break;
          case TalkingStatus.Left:
            break;
          default:
            await endTalking(this.token, this.externalMeetingId);
            break;
        }
      } catch (e) {
        console.log(e);
      }  

      try {
        await leavedTalking(this.token, this.externalMeetingId);
      } catch (e) {
        console.log(e);
      }
    }

    await this.stopAudioVideoAndInitalize();
    this.initializeMeetingManager();

    this.updateMeetingStatus(MeetingStatus.EndingEnd);
  }

  async stopAudioVideoAndInitalize() {
    if (this.audioVideo) {
      this.audioVideo.stopContentShare();
      this.audioVideo.stopLocalVideoTile();
      this.audioVideo.unbindAudioElement();

      try {
        await this.audioVideo.stopVideoInput();
        await this.meetingSession?.deviceController.chooseAudioOutput(null);
        await this.meetingSession?.deviceController.destroy();
      } catch (error) {
        console.log(
          'MeetingManager failed to clean up media resources on leave'
        );
      }
    }
    this.publishAudioVideo();
  }

  async initializeMeetingSession(configuration: MeetingSessionConfiguration, eventController: EventController) {
    if (this.videoUplinkBandwidthPolicy) {
      configuration.videoUplinkBandwidthPolicy =
        this.videoUplinkBandwidthPolicy;
    }

    const logger = this.logger ?? this.createLogger();

    if (this.videoDownlinkBandwidthPolicy) {
      configuration.videoDownlinkBandwidthPolicy =
        this.videoDownlinkBandwidthPolicy;
    }

    const deviceController = new DefaultDeviceController(logger);

    this.meetingSession = new DefaultMeetingSession(
      configuration,
      logger,
      deviceController,
      eventController
    );

    this.audioVideo = this.meetingSession.audioVideo;
    
    if (eventController) {
      eventController.addObserver(this.eventDidReceiveRef);
    } else {
      this.meetingSession.eventController.addObserver(this.eventDidReceiveRef);
    }

    this.setupAudioVideoObserver();

    await this.startAudioInputAndOutput();

    this.publishAudioVideo();
  }

  async startAudioInputAndOutput() {
    if (this.audioVideo) {
      const audioOutputDevices = await this.audioVideo.listAudioOutputDevices();
      console.log("audioOutputDevices", audioOutputDevices);
  
      if (audioOutputDevices[0]) {
        await this.audioVideo.chooseAudioOutput(audioOutputDevices[0].deviceId);
      }
      const audioInputDevices = await this.audioVideo?.listAudioInputDevices();
      await this.audioVideo?.startAudioInput(audioInputDevices[0].deviceId);  
    }
  }

  async startVideoInput() {
    const videoInputDevices = await this.audioVideo?.listVideoInputDevices();
    if (videoInputDevices) {
      this.audioVideo?.chooseVideoInputQuality(1280, 720, 3);
      await this.audioVideo?.startVideoInput(videoInputDevices[0].deviceId);
    }
  }

  async stopVideoInput() {
    await this.audioVideo?.stopVideoInput();
  }

  createLogger() {
    return new ConsoleLogger("SDK", this.logLevel);
  }

  onJoinedAttendee(attendeeId: string) {
    if (this.myAttendee?.AttendeeId != attendeeId) {
      this.updateTalkingStatus(TalkingStatus.Talking);
    } else {
      // 自分自身が参加したのでJoinedにする
      this.joinedTalking();
    }
  }

  updateMeetingStatus(meetingStatus: MeetingStatus) {
    this.meetingStatus = meetingStatus;
    this.publishMeetingStatus();

    if ([MeetingStatus.Error].some(status => status == meetingStatus)) {
      this.errorEnd();
    }

    if ([MeetingStatus.Ended].some(status => status == meetingStatus)) {
      this.showAddressPage();
    }
  }

  updateTalkingStatus(talkingStatus: TalkingStatus) {
    // 自分の参加時に相手がすでにいる場合があるので、トーク中から参加済みには遷移しないようにする
    if (this.talkingStatus == TalkingStatus.Talking  && talkingStatus == TalkingStatus.Joined) return;

    this.talkingStatus = talkingStatus;
    this.publishTalkingStatus();
  }

  audioVideoDidStart = () => {
    console.log(
      "[MeetingManager audioVideoDidStart] Meeting started successfully"
    );

    this.updateMeetingStatus(MeetingStatus.Started);
  };

  audioVideoDidStop = (sessionStatus) => {
    const sessionStatusCode = sessionStatus.statusCode();

    switch (sessionStatusCode) {
      case MeetingSessionStatusCode.MeetingEnded:
        console.log(
          `[MeetingManager audioVideoDidStop] Meeting ended for all: ${sessionStatusCode}`
        );

        // 話し中で終了された時または自分で終了した時は終了処理を行う
        if (
          ![
            MeetingStatus.Ending,
            MeetingStatus.Ended,
            MeetingStatus.Error
          ].some(status => status == this.meetingStatus)
        ) {
          this.left();  
        }
        break;
      case MeetingSessionStatusCode.Left:
        console.log(
          `[MeetingManager audioVideoDidStop] Left the meeting: ${sessionStatusCode}`
        );
        break;
      case MeetingSessionStatusCode.AudioJoinedFromAnotherDevice:
        console.log(
          `[MeetingManager audioVideoDidStop] Meeting joined from another device: ${sessionStatusCode}`
        );
        break;
      default:
        // The following status codes are Failures according to MeetingSessionStatus
        if (sessionStatus.isFailure()) {
          console.log(
            `[MeetingManager audioVideoDidStop] Non-Terminal failure occured: ${sessionStatusCode}`
          );
        } else if (sessionStatus.isTerminal()) {
          console.log(
            `[MeetingManager audioVideoDidStop] Terminal failure occured: ${sessionStatusCode}`
          );
        }
        console.log(
          `[MeetingManager audioVideoDidStop] session stopped with code ${sessionStatusCode}`
        );

        this.left();
    }

    this.audioVideo?.removeObserver(this.audioVideoObserver);
  };

  async onRosterIsOnlyMe() {
    // トーク中に発生したので、相手がネットワークエラーとみなし会話終了とする
    if (this.talkingStatus == TalkingStatus.Talking) {
      await this.networkErrorEnd();
    }

    // 参加済み中に発生したので、相手が応答なしとみなし会話終了とする
    if (this.talkingStatus == TalkingStatus.Joined) {
      await this.timeoutMeeting();
    }
  }
  
  setupAudioVideoObserver() {
    if (!this.audioVideo) {
      return;
    }

    const audioVideoObserver = {
      audioVideoDidStart: this.audioVideoDidStart,
      audioVideoDidStop: this.audioVideoDidStop,
    };

    this.audioVideo.addObserver(audioVideoObserver);
  }

  subscribeToCustomer = (callback: (customer) => void) => {
    this.customerObservers.push(callback);
  };

  unsubscribeFromCustomer = (callbackToRemove) => {
    this.customerObservers = this.customerObservers.filter(
      (callback) => callback !== callbackToRemove
    );
  };

  publishCustomer = () => {
    this.customerObservers.forEach((callback) => {
      callback(this.customer);
    });
  };

  subscribeToMeetingStatus = (callback) => {
    this.meetingStatusObservers.push(callback);
    callback(this.meetingStatus);
  };

  unsubscribeFromMeetingStatus = (callbackToRemove) => {
    this.meetingStatusObservers = this.meetingStatusObservers.filter(
      (callback) => callback !== callbackToRemove
    );
  };

  publishMeetingStatus = () => {
    this.meetingStatusObservers.forEach((callback) => {
      callback(this.meetingStatus);
    });
  };

  subscribeToTalkingStatus = (callback) => {
    this.talkingStatusObservers.push(callback);
    callback(this.talkingStatus);
  };

  unsubscribeFromTalkingStatus = (callbackToRemove) => {
    this.talkingStatusObservers = this.talkingStatusObservers.filter(
      (callback) => callback !== callbackToRemove
    );
  };

  publishTalkingStatus = () => {
    this.talkingStatusObservers.forEach((callback) => {
      callback(this.talkingStatus);
    });
  };

  subscribeToAudioVideo = (callback) => {
    console.log("subscribeToAudioVideo");
    this.audioVideoCallbacks.push(callback);
  };

  unsubscribeFromAudioVideo = (callbackToRemove) => {
    console.log("unsubscribeFromAudioVideo");
    this.audioVideoCallbacks = this.audioVideoCallbacks.filter(
      (callback) => callback !== callbackToRemove
    );
  };

  publishAudioVideo = () => {
    this.audioVideoCallbacks.forEach((callback) => {
      callback(this.audioVideo);
    });
  };

  subscribeToEventDidReceive = (callback) => {
    this.meetingEventObserverSet.add(callback);
  };

  unsubscribeFromEventDidReceive = (callbackToRemove) => {
    this.meetingEventObserverSet.delete(callbackToRemove);
  };

  publishEventDidReceiveUpdate = (name: EventName, attributes: EventAttributes) => {
    this.meetingEventObserverSet.forEach((callback) =>
      callback(name, attributes)
    );
  };

  private async startTalkingToServer() {
    this.updateMeetingStatus(MeetingStatus.Creating);

    try {
      const data = await startTalking(this.token);
      console.log(data)

      this.externalMeetingId = data.meeting.ExternalMeetingId;

      this.updateMeetingStatus(MeetingStatus.Created);

      return this.externalMeetingId;
    } catch (e) {
      this.updateMeetingStatus(MeetingStatus.Error);
      console.log(e);
    }
  }

  async getTalkingInformation() {
    try {
      const data = await joinTalking(
        this.token,
        this.externalMeetingId
      );

      this.meeting = data.meeting;
      this.myAttendee = data.attendee;

      this.publishCustomer();
    } catch (e) {
      console.log(e);
    }
  }
}
export default MeetingManager;