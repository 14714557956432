import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {  Dimensions } from 'react-native';

type PortraitProviderContextType = {
  portrait: boolean;
};

export const PortraitProviderContext = createContext<PortraitProviderContextType>({portrait: false});

const PortraitProvider = ({ children }) => {
  let { width, height } = Dimensions.get('window');
  const [portrait, setPortrait] = useState(height >= width);

  useEffect(() => {
    const dimensionsSubscription = Dimensions.addEventListener("change", () => {
      let { width, height } = Dimensions.get('window');
      setPortrait(height >= width);
    });
  
    return () => {
      dimensionsSubscription.remove();
    }
  }, [setPortrait])

  const value = useMemo(
    () => ({
      portrait
    }),
    [portrait]
  );

  return (
    <PortraitProviderContext.Provider value={value}>
      {children}
    </PortraitProviderContext.Provider>
  );
};

const usePortrait = () => {
  const portrait = useContext(PortraitProviderContext);
  return portrait;
};

export { usePortrait, PortraitProvider };
