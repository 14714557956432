import React, { FC } from 'react';
import { View } from 'react-native';
import TalkingContainer from '../containers/TalkingContainer';
import { MeetingProvider } from '../providers/MeetingProvider';

export const TalkingFamily: FC<{}> = (props) => {
  return (
    <View style={{
      height: '100%'
    }}>
      <MeetingProvider>
        <TalkingContainer />
      </MeetingProvider>
    </View>
  )
}