import { FontAwesomeIcon as FontAwesomeReact } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon as FontAwesomeNative } from '@fortawesome/react-native-fontawesome';
import { number } from 'prop-types';
import React from 'react';
import { Platform, StyleSheet } from 'react-native';

FaIcon.propTypes = {
  size: number,
};

export default function FaIcon({ icon, size, style, ...props }) {
  if (Platform.OS === 'web') {
    const webStyles = StyleSheet.flatten([style, { width: size, height: size }]);
    return <FontAwesomeReact {...props} style={webStyles} icon={icon} />;
  }

  return <FontAwesomeNative {...props} size={size} style={style} icon={icon} />;
}
