import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Alert, FlatList, Image, Modal, SafeAreaView, StyleSheet, Text, View, Dimensions } from "react-native";
import { useNavigate } from 'react-router';
import FaIcon from '../components/FaIcon';
import StyledButton from '../components/StyledButton';
import { API_HOST } from '../constants';
import { useAuth } from '../routes/route';

export const Address: FC<{}> = (props) => {

  const navigate = useNavigate()
  const navigation = useNavigation();
  
  const auth = useAuth();
  const [modalVisible, setModalVisible] = useState(false);
  const [target, setTarget] = useState<any>(null);
  const [families, setFamilies] = useState([]);
  const [otherCalling, setOtherCalling] = useState(false);
  const [talkingPartnerName, setTalkingPartnerName] = useState("誰か")

  useEffect(() => {
    fetch(`${API_HOST}/api/v1/family/addresses`, {
      headers: {
        Authorization: 'Bearer ' + auth.authToken
      }
    }).then(r => {
      if (r.ok) {
        r.json().then(data => {
          setFamilies(data.data.families)
        })
      }
    })
  }, [auth.user])

  const renderItem = ({ item }) => (
    <View style={styles.item} >
      <View style={styles.profileImgContainer}>
        <Image source={require('../person.png')} style={styles.profileImg}></Image>
      </View>
      <View style={{ maxWidth: 'calc(100% - 82px)' }}>
        <Text style={styles.name}>{item.last_name} {item.first_name}</Text>
        <Text style={styles.birthday}>{dayjs(item.birthday).format('YYYY年MM月DD日')}({dayjs().diff(dayjs(item.birthday), 'year')}歳)</Text>

        <StyledButton
          title={<div><FaIcon icon="phone-alt" size={22} color='#fff' style={{ position: 'relative', top: '3px', left: '-3px' }} />話しかける</div>}
          color="#6DBC89"
          onPress={() => {
            if (item.device_token) {
              setTarget(item)
              setModalVisible(!modalVisible)
            }
            else {
              alert('話しかけられません')
            }
          }} />
      </View>
    </View>
  );

  return (
    <SafeAreaView onLayout={useCallback(() => {
      // console.log('Address', portrait)
    }, [])} style={{ height: '100%' }}>
      <FlatList
        data={families}
        renderItem={renderItem}
        keyExtractor={(item, i) => `${i}-${item.id}`}
        contentContainerStyle={{
          flexGrow: 1,
        }}
      />

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View>
              <View style={{ alignContent: 'center', alignItems: 'center', marginLeft: '20px' }}>
                <View style={styles.profileImgContainer}>
                  <Image source={require('../person.png')} style={styles.profileImg}></Image>
                </View>
              </View>
              <View>
                <Text style={styles.modalText}>
                  {target?.last_name} {target?.first_name}
                </Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '50%' }}>
                  <StyledButton
                    title="一覧へもどる"
                    color="#DB0000"
                    icon={<FaIcon icon="times" color='#fff' size={30} style={{}} />}
                    center={true}
                    onPress={() => {
                      setTarget(null)
                      setModalVisible(!modalVisible)
                    }}
                    buttonStyle={{ width: '10em' }} />
                </View>
                <View style={{ width: '50%' }}>
                  <StyledButton
                    title="話しかける"
                    color="#6DBC89"
                    icon={<FaIcon icon="phone-alt" color='#fff' size={30} style={{}} />}
                    center={true}
                    onPress={() => {
                      // ミーティングスタート
                      setModalVisible(false);
                      navigation.setOptions({
                        tabBarStyle: { display: 'none' }
                      })
                      navigate(`/app/talkingFamily`);
                    }}
                    buttonStyle={{ width: '10em', marginHorizontal: '1em' }}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      {/* 通話中モーダル */}
      <Modal
        animationType="none"
        transparent={false}
        visible={otherCalling}>

        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View>
              <View style={{ alignContent: 'center', alignItems: 'center', marginLeft: '20px' }}>
                <Text style={{ marginTop: '1em' }}>{target?.last_name} {target?.first_name}は現在 { talkingPartnerName } と話中です。</Text>
                <Text style={{ textAlign: 'center', marginVertical: 30 }}>30分ほど経ってからおかけください。<br />
                </Text>
              </View>
            </View>
            <StyledButton
              title={<div><FaIcon icon="phone-alt" size={20} color='#fff' style={{ position: 'relative', top: '3px', left: '-3px' }} />呼び出しを停止</div>}
              buttonStyle={{ width: '15em' }}
              color={'#DB0000'}
              onPress={() => setOtherCalling(false)} />
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({

  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },
  item: {
    paddingVertical: 20,
    paddingRight: 20,
    marginHorizontal: 20,
    flexDirection: 'row',
    borderBottomColor: "#6DBC89",
    borderBottomWidth: 1
  },
  name: {
    fontSize: 32,
  },
  birthday: {
    fontSize: 24,
    marginBottom: 10
  },
  profileImgContainer: {
    marginLeft: 8,
    height: 82,
    width: 82,
    borderRadius: 40,
    borderWidth: 1,
    backgroundColor: '#fff',
    marginRight: 20,
  },
  profileImg: {
    height: 80,
    width: 80,
    borderRadius: 40,
  },
});
