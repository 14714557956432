import React, { FC, useCallback, useState } from 'react';
import { Image, SafeAreaView, View, Dimensions } from "react-native";
import { usePortrait } from '../providers/PortraitProvider';

export const ComingSoon1: FC<{}> = (props) => {
  const {portrait} = usePortrait();

  return (
    <SafeAreaView onLayout={useCallback(() => {
      console.log('ComingSoon1', portrait)
    }, [])} >
      <View style={{
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Image source={require('../comingsoon1.png')}
          style={portrait ? { width: 335, height: 420 } : { width: 200, height: 300 }} ></Image>
      </View>
    </SafeAreaView>
  )
}
