// TODO: .env(react-native-config) が ios / android 向けだったので都度かかないといけない・調べる
// https://github.com/tanhauhau/react-web-config が使える？

// export const API_HOST = process.env.CAREBEE_API_HOST ?? 'http://localhost:3000';
// export const API_HOST = process.env.CAREBEE_API_HOST ?? 'https://dev.supporter.carebee.io';
export const API_HOST = process.env.CAREBEE_API_HOST ?? 'https://supporter.carebee.io';
export const SKYWAY_API_KEY = '5ce1a85b-3b69-46ef-a43d-9c283839fbc4'; // process.env.SKYWAY_API_KEY;
export const SKYWAY_DEBUG = parseInt(process.env.SKYWAY_DEBUG ?? '1'); // NONE:0, ERROR:1, WARN:2, FULL:3

export const RECORDING_SLICE_SECONDS = 30; // 録画時間
export const CAN_RECORDING = true; //
