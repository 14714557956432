export const DEVICE = {
  IOS: "iOS",
  ANDROID: "Android",
  OTHER: "Other",
} as const;

export type Device = typeof DEVICE[keyof typeof DEVICE];

export const BROWSER = {
  SAFARI: "Safari",
  CHROME: "Chrome",
  OTHER: "Other",
} as const;

export type Browser = typeof BROWSER[keyof typeof BROWSER];

export const isMobile = (): boolean => {
  var ua = navigator.userAgent;
  return (ua.indexOf('iPhone') > 0 || ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0 || ua.indexOf('Mobile') > 0 )
}

export const getMobileOS = (): Device => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return DEVICE.ANDROID
  }
  else if (/iPad|iPhone|iPod/.test(ua)){
    return DEVICE.IOS
  }

  return DEVICE.OTHER
}

export const getBrowser = (): Browser => {
  const ua = navigator.userAgent
  const lowerCaseUa = ua.toLowerCase()

  if (lowerCaseUa.indexOf("chrome") != -1) {
    return BROWSER.CHROME;
  } else if (lowerCaseUa.indexOf("safari") != -1) {
    return BROWSER.SAFARI;
  } else {
    return BROWSER.OTHER;
  }
}
