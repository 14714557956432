
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import React, { createContext, useContext, useEffect, useMemo, useState, VFC } from 'react';
import { SafeAreaView } from 'react-native';
import { Outlet, useNavigate } from 'react-router';
import { BrowserRouter, Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { Header } from '../components/Header';
import { API_HOST } from '../constants';
import carebeeAuthProvider from '../hooks/auth';
import { Address } from '../pages/Address';
import { ComingSoon1 } from '../pages/ComingSoon1';
import { ComingSoon2 } from '../pages/ComingSoon2';
import { Login } from '../pages/Login';
import { Talking } from '../pages/Talking';
import { useCookies } from "react-cookie";
import { Histories } from '../pages/Histories';
import FaIcon from '../components/FaIcon';
import { TalkingFamily } from '../pages/TalkingFamily';
import { PortraitProvider } from '../providers/PortraitProvider';

const routes: VFC = () => {

  return (
    <BrowserRouter>
      <AuthProvider>
        <PortraitProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/app" element={<RequireAuth><Home /></RequireAuth>} >
              <Route path="address" element={<Address />} />
              <Route path=":id">
                <Route path="talking" element={<Talking />} />
              </Route>
              <Route path="talkingFamily" element={<TalkingFamily />}></Route>
            </Route>
          </Routes>
        </PortraitProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

const Tab = createBottomTabNavigator();

function Home() {

  const [hideTab, setHideTab] = useState(false);

  return (
    <SafeAreaView onLayout={() => {
      // console.log('route', portrait)
    }} style={{ flex: 1 }}>

      <Header />

      <NavigationContainer>
        <Tab.Navigator
          screenOptions={({ route }) => ({
            tabBarIcon: ({ focused, color, size }) => {
              let iconName;

              switch (route.name) {
                case '話しかける': iconName = 'phone-alt'; break;
                case '通話履歴': iconName = 'history'; break;
                case 'チャット': iconName = 'comments'; break;
                case '設定': iconName = 'cog'; break;
              }
              return <FaIcon icon={iconName} size={size} color={color} style={{}} />;
            },
            tabBarActiveTintColor: '#fff',
            tabBarInactiveTintColor: '#6DBC89',
            tabBarActiveBackgroundColor: '#6DBC89',
            tabBarLabelStyle: {
              fontWeight: 'bold',
              fontSize: 16
            },
            tabBarStyle: {
              height: 60
            },
            headerShown: false,
            tabBarOptions: {
            },
          })}
          sceneContainerStyle={{
            backgroundColor: '#fff',
          }}
        >
          <Tab.Screen name="話しかける" component={() => {
            return <Outlet context={setHideTab} />
          }} options={{ tabBarStyle: { display: hideTab ? 'none' : 'flex' } }} />
          <Tab.Screen name="通話履歴" component={Histories} />
          <Tab.Screen name="チャット" component={ComingSoon1} />
          <Tab.Screen name="設定" component={ComingSoon2} />
        </Tab.Navigator>
      </NavigationContainer>
    </SafeAreaView>

  )
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

interface AuthContextType {
  user: any;
  authToken: string;
  signin: (email: string, password: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

let AuthContext = createContext<AuthContextType>(null!);

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<any>(null);
  const [authToken, setAuthToken] = useState<any>(null);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const query = new URLSearchParams(useLocation().search);

  // console.log(cookies.token)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { from }: any = location.state ?? { from: { pathname: "/app/address" } };
    const token = query.get('token') ?? cookies?.token

    if (!user?.id && token) {
      fetch(`${API_HOST}/api/v1/family/me`, {
        headers: {
          Authorization: 'Bearer ' + (token)
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(data => {
            setCookie('token', data.auth_token);
            setAuthToken(data.auth_token);
            setUser(data.user);
            return navigate(from);
          });
        }
      })
    }
  }, [user])

  const signin = (email: string, password: string, callback: VoidFunction) => {
    // ログイン
    fetch(`${API_HOST}/api/v1/family/auth_user`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      // mode: 'cors',
      body: JSON.stringify({
        email: email,
        password: password
      })
    }).then(r => {
      if (r.ok) {
        r.json().then(data => {
          return carebeeAuthProvider.signin(() => {
            setCookie('token', data.auth_token);
            setAuthToken(data.auth_token)
            setUser(data.user);
            callback();
          });
        });
      }
    });
  };

  const signout = (callback: VoidFunction) => {
    // ログアウト
    return carebeeAuthProvider.signout(() => {
      removeCookie('token');
      setUser(null);
      setAuthToken(null);
      callback();
    });
  };

  const value = { user, authToken, signin, signout };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export default routes({});
