import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAudioVideo } from "../AudioVideoProvider";
import { useMeetingManager } from "../MeetingProvider";
import { State } from "./state";

const LocalVideoContext = createContext<State | null>(null);

const LocalVideoProvider = ({ children }) => {
  const meetingManager = useMeetingManager();
  const audioVideo = useAudioVideo();
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);
  const [tileId, setTileId] = useState(null);

  const toggleVideo = useCallback(async () => {
    try {
      if (isVideoEnabled) {
        await meetingManager.stopVideoInput();
        setIsVideoEnabled(false);
      } else {
        await meetingManager.startVideoInput();
        audioVideo?.startLocalVideoTile();
        setIsVideoEnabled(true);
      }
    } catch (error) {
      console.log("Failed to toggle video");
      console.log(error);
    }
  }, [audioVideo, isVideoEnabled]);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    const observer = {
      videoTileDidUpdate: (tileState) => {
        if (
          !tileState.localTile ||
          !tileState.tileId ||
          tileId === tileState.tileId
        ) {
          return;
        }

        setTileId(tileState.tileId);
      },
    };
    audioVideo.addObserver(observer);

    return () => audioVideo.removeObserver(observer);
  }, [audioVideo, tileId]);

  const value = useMemo(
    () => ({
      tileId,
      isVideoEnabled,
      toggleVideo,
    }),
    [tileId, isVideoEnabled, toggleVideo]
  );

  return (
    <LocalVideoContext.Provider value={value}>
      {children}
    </LocalVideoContext.Provider>
  );
};
const useLocalVideo = () => {
  const context = useContext(LocalVideoContext);

  if (!context) {
    throw new Error("useLocalVideo must be used within LocalVideoProvider");
  }

  return context;
};

export { LocalVideoProvider, useLocalVideo };
