import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import useCustomer from "../../hooks/useCustomer";
import { MeetingStatus } from "../../types/MeetingStatus";
import { useAudioVideo } from "../AudioVideoProvider";
import { useChat } from "../ChatProvider";
import { useMeetingManager } from "../MeetingProvider";
import { State } from "./state";

const CustomerVideoContext = createContext<State | null>(null);

const CustomerVideoProvider = ({ children }) => {
  const audioVideo = useAudioVideo();
  const [tileId, setTileId] = useState(null);
  const customer = useCustomer();
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);
  const { sendVideoOnOffChatMessage } = useChat();
  const meetingManager = useMeetingManager();

  useEffect(() => {
    if (!audioVideo) {
      return;
    }
  }, [audioVideo]);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    const observer = {
      videoTileDidUpdate: (tileState) => {
        if (
          tileState?.boundAttendeeId &&
          tileState?.tileId &&
          !tileState.isContent &&
          !tileState.localTile
        ) {
          setTileId(tileState.tileId);
        }
      },
    };
    audioVideo.addObserver(observer);

    return () => audioVideo.removeObserver(observer);
  }, [audioVideo, tileId, customer, meetingManager]);

  const toggleVideo = useCallback(async () => {
    console.log("toggleVideo")
    try {
      await sendVideoOnOffChatMessage(isVideoEnabled);
      setIsVideoEnabled(!isVideoEnabled);
    } catch (error) {
      console.log("Failed to toggle video");
      console.log(error);
    }
  }, [isVideoEnabled, sendVideoOnOffChatMessage]);

  const value = useMemo(
    () => ({
      tileId,
      isVideoEnabled,
      toggleVideo,
    }),
    [tileId, isVideoEnabled, toggleVideo]
  );

  return (
    <CustomerVideoContext.Provider value={value}>
      {children}
    </CustomerVideoContext.Provider>
  );
};
const useCustomerVideo = () => {
  const context = useContext(CustomerVideoContext);

  if (!context) {
    throw new Error(
      "useCustomerVideo must be used within CustomerVideoProvider"
    );
  }

  return context;
};

export { CustomerVideoProvider, useCustomerVideo };
