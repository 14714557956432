import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAudioVideo } from "../AudioVideoProvider";
import { State } from "./state";

const LocalAudioOutputContext = createContext<State | null>(null);

const LocalAudioOutputProvider = ({ children }) => {
  const audioVideo = useAudioVideo();
  const audioRef = useRef(null);
  const [isAudioOn] = useState(true);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    if (audioRef.current) {
      (async (element) => {
        try {
          await audioVideo.bindAudioElement(element);
        } catch (e) {
          console.log(`Failed to bind audio element. ${e}`);
        }
      })(audioRef.current);
    }
    return () => {
      audioVideo.unbindAudioElement();
    };
  }, [audioVideo]);

  const value = useMemo(() => ({ isAudioOn }), [isAudioOn]);

  return (
    <LocalAudioOutputContext.Provider value={value}>
      {children}
      <audio ref={audioRef} style={{ display: "none" }} />
    </LocalAudioOutputContext.Provider>
  );
};

const useLocalAudioOutput = () => {
  const context = useContext(LocalAudioOutputContext);
  if (!context) {
    throw new Error(
      "useLocalAudioOutput must be used within LocalAudioOutputProvider"
    );
  }
  return context;
};

export { LocalAudioOutputProvider, useLocalAudioOutput };
