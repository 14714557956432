import { FC, useCallback, useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, TextInput, View, Dimensions } from 'react-native';
import { useLocation, useNavigate } from 'react-router';
import { Header } from '../components/Header';
import StyledButton from '../components/StyledButton';
import '../icons';
import { useAuth } from '../routes/route';
import { useCookies } from 'react-cookie';

export const Login: FC<{ }> = (props) => {

  // FIXME: 後で消す
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // 遷移元またはアドレス帳へ遷移
  const { from }: any = location.state ?? { from: { pathname: "/app/address" } };


  const onClickLoginButton = () => {
    setMessage('');

    if (email && password) {
      auth.signin(email, password, () => {
        return navigate(from)
      });
    }
    else {
      setMessage('メールアドレスまたはパスワードが入力されていません。');
    }
  };

  return (
    <SafeAreaView onLayout={useCallback(() => {
      // console.log('Login', portrait)
    }, [])} style={{ flex: 1, paddingHorizontal: 32 }}>

      <Header />

      <View style={styles.form}>
        <View>
          <Text style={styles.formLabel}>ログインID</Text>
          <TextInput
            placeholder="メールアドレス"
            onChangeText={setEmail}
            value={email}
            autoComplete="email"
            keyboardType='email-address'
            style={styles.formInput}
          />
        </View>
        <View style={{ marginBottom: '1em' }}>
          <Text style={styles.formLabel}>パスワード</Text>
          <TextInput
            placeholder="パスワード"
            onChangeText={setPassword}
            value={password}
            autoComplete="password"
            keyboardType='visible-password'
            style={styles.formInput}
          />
        </View>
        <View>
          <StyledButton title="ログイン" color={'#71BC89'} onPress={onClickLoginButton} />
        </View>
        <Text>{message}</Text>
      </View>
    </SafeAreaView>
  );
}


const styles = StyleSheet.create({
  form: {
  },
  formLabel: {
    width: '100%',
    color: '#71BC89',
    fontWeight: 'bold',
    display: 'flex',
    fontSize:24
  },
  formInput: {
    width: '100%',
    marginBottom: '1em',
    padding: '.75em',
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    fontSize: 20
  }
});
