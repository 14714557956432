export const MeetingStatus = {
  Init: "Init",
  Ready: "Ready",
  Creating: "Creating",
  Created: "Created",
  Started: "Started",
  Ending: "Ending",
  EndingEnd: "EndingEnd",
  Ended: "Ended",
  Error: "Error",
} as const;
 
export type MeetingStatus = typeof MeetingStatus[keyof typeof MeetingStatus];