import dayjs from 'dayjs';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FlatList, Image, SafeAreaView, StyleSheet, Text, View, Dimensions } from "react-native";
import { useNavigate } from 'react-router';
import { API_HOST } from '../constants';
import { usePortrait } from '../providers/PortraitProvider';
import { useAuth } from '../routes/route';

export const Histories: FC<{}> = (props) => {

  const navigate = useNavigate()
  const {portrait} = usePortrait();

  const auth = useAuth();
  const [modalVisible, setModalVisible] = useState(false);
  const [target, setTarget] = useState<any>(null);
  const [families, setFamilies] = useState([])

  useEffect(() => {
    fetch(`${API_HOST}/api/v1/family/call_histories`, {
      headers: {
        Authorization: 'Bearer ' + auth.authToken
      }
    }).then(r => {
      r.json().then(data => {
        setFamilies(data.data.callHistories)
      })
    })
  }, [auth.user])

  const statusLabel = (status) => {
    switch (status) {
      case 'disconnect': return '切断';
      case 'error': return 'エラー';
      case 'cancel': return '中断';
      case 'end': return '終了';
      case 'timeout': return '応答なし';
      default: return '不明'
    }
  }

  const renderItem = ({ item }) => (
    <View style={styles.item} >
      <View style={styles.profileImgContainer}>
        <Image source={require('../person.png')} style={styles.profileImg}></Image>
      </View>
      <View style={{ width: 'calc(100% - 82px)' }}>
        <Text style={{ fontSize: 17 }}>{dayjs(item.created_at).format('YYYY年MM月DD日 HH:mm:ss')}</Text>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Text style={styles.name}>{item.last_name} {item.first_name}</Text>
          </div>
          <div style={{ float: 'right' }}>
            <div style={{ borderRadius: '40px', textAlign: 'center', alignContent: 'center', verticalAlign: 'middle', lineHeight: '40px', width: '70px', border: '1px solid #6DBC89' }}>
              <Text>{statusLabel(item.status)}</Text>
            </div>
          </div>
        </div>
      </View>
    </View>
  );

  return (
    <SafeAreaView onLayout={useCallback(() => {
      console.log('ComingSoon2', portrait)
    }, [])} style={{ height: '100%' }}>
      <FlatList
        data={families}
        renderItem={renderItem}
        keyExtractor={(item, i) => `${i}-${item.id}`}
        contentContainerStyle={{
          flexGrow: 1,
        }}
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({

  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  item: {
    paddingVertical: 20,
    marginHorizontal: 20,
    flexDirection: 'row',
    borderBottomColor: "#6DBC89",
    borderBottomWidth: 1
  },
  name: {
    fontSize: 32,
  },
  profileImgContainer: {
    marginLeft: 8,
    height: 52,
    width: 52,
    borderRadius: 40,
    borderWidth: 1,
    backgroundColor: '#fff',
    marginRight: 20,
  },
  profileImg: {
    height: 50,
    width: 50,
    borderRadius: 40,
  },
});
