import { useNavigation } from "@react-navigation/native";
import { AudioInputDevice } from "amazon-chime-sdk-js";
import { createContext, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../routes/route";
import { AudioVideoProvider } from "../AudioVideoProvider";
import { ChatProvider } from "../ChatProvider";
import { CustomerVideoProvider } from "../CustomerVideoProvider";
import { LocalAudioOutputProvider } from "../LocalAudioOutputProvider";
import { LocalVideoProvider } from "../LocalVideoProvider";
import { MeetingEventProvider } from "../MeetingEventProvider";
import { RosterProvider } from "../RosterProvider";
import MeetingManager from "./MeetingManager";

interface Props {
  onDeviceReplacement?: (
    nextDevice: string,
    currentDevice: AudioInputDevice
  ) => Promise<AudioInputDevice>;
  meetingManager?: MeetingManager;
}

export const MeetingContext = createContext<MeetingManager | null>(null);

export const MeetingProvider: React.FC<Props> = ({
  onDeviceReplacement,
  meetingManager: meetingManagerProp,
  children,
}) => {
  const auth = useAuth();
  const navigate = useNavigate()
  const navigation = useNavigation();

  const showAddressPage = useCallback(() => {
    navigation.setOptions({
      tabBarStyle: { display: 'flex' }
    })
  
    navigate('/app/address');  
  }, [navigate, navigation])

  const [meetingManager] = useState(
    () => meetingManagerProp || new MeetingManager(auth.authToken, showAddressPage)
  );

  return (
    <MeetingContext.Provider value={meetingManager}>
      <MeetingEventProvider>
        <AudioVideoProvider>
          <RosterProvider>
            <ChatProvider>
              <CustomerVideoProvider>
                <LocalVideoProvider>
                  <LocalAudioOutputProvider>
                    {children}
                  </LocalAudioOutputProvider>
                </LocalVideoProvider>
              </CustomerVideoProvider>
            </ChatProvider>
          </RosterProvider>
        </AudioVideoProvider>
      </MeetingEventProvider>
    </MeetingContext.Provider>
  )
}

export const useMeetingManager = (): MeetingManager => {
  const meetingManager = useContext(MeetingContext);

  if (!meetingManager) {
    throw new Error('useMeetingManager must be used within MeetingProvider');
  }

  return meetingManager;
};