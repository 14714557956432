import { FC, useCallback, useState } from "react";
import { Modal, SafeAreaView, View, Text, StyleSheet } from "react-native";
import ActionBar from "../../components/features/ActionBar";
import CustomerVideo from "../../components/features/video/CustomerVideo";
import LocalVideo from "../../components/features/video/LocalVideo";
import { usePortrait } from "../../providers/PortraitProvider";
import { useEffect } from "react";
import { useMeetingManager } from "../../providers/MeetingProvider";
import useMeetingStatus from "../../hooks/useMeetingStatus";
import { MeetingStatus } from "../../types/MeetingStatus";
import StyledButton from "../../components/StyledButton";
import useTalkingStatus from "../../hooks/useTalkingStatus";
import { TalkingStatus } from "../../types/TalkingStatus";

export const MessageType = {
  DEFAULT: "DEFAULT"
} as const;
 
export type MessageType = typeof MessageType[keyof typeof MessageType];

type ModalMessage = {
  text: string,
  messageType: MessageType
}

type ModalAction = {
  text: string,
}

const modals = {
  waiting: {
    messages: [
      {text: "呼び出し中...", messageType: MessageType.DEFAULT},
      {text: "しばらくお待ち下さい。", messageType: MessageType.DEFAULT}
    ],
    action: {
      text: "キャンセル"
    }
  },
  network_error: {
    messages: [
      {text: "ネットワークエラーのため、", messageType: MessageType.DEFAULT},
      {text: "会話を終了しました。", messageType: MessageType.DEFAULT},
    ],
    action: {
      text: "OK"
    }
  },
  error: {
    messages: [
      {text: "エラーが発生したため、", messageType: MessageType.DEFAULT},
      {text: "会話を終了しました。", messageType: MessageType.DEFAULT},
    ],
    action: {
      text: "OK"
    }
  },
  timeout: {
    messages: [
      {text: "接続できませんでした。", messageType: MessageType.DEFAULT},
      {text: "タブレットの電源が切れているか", messageType: MessageType.DEFAULT},
      {text: "Carebeeが起動されてません。", messageType: MessageType.DEFAULT},
      {text: "時間をあけてから再度おかけください。", messageType: MessageType.DEFAULT},
    ],
    action: {
      text: "OK"
    }
  },
  cancel: {
    messages: [
      {text: "呼び出しを", messageType: MessageType.DEFAULT},
      {text: "キャンセルしました。", messageType: MessageType.DEFAULT},
    ],
    action: {
      text: "OK"
    }
  },
  left: {
    messages: [
      {text: "会話から", messageType: MessageType.DEFAULT},
      {text: "退出しました。", messageType: MessageType.DEFAULT},
    ],
    action: {
      text: "OK"
    }
  },
  ending: {
    messages: [
      {text: "会話を", messageType: MessageType.DEFAULT},
      {text: "終了しています。", messageType: MessageType.DEFAULT},
    ],
    action: null
  },
  end: {
    messages: [
      {text: "会話を", messageType: MessageType.DEFAULT},
      {text: "終了しました。", messageType: MessageType.DEFAULT},
    ],
    action: {
      text: "OK"
    }
  }
}

const TalkingContainer: FC<{}> = (props) => {
  const {portrait} = usePortrait();
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const talkingStatus = useTalkingStatus();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);
  const [modalContents, setModalContents] = useState<{messages: ModalMessage[], action: ModalAction | null}>(modals.waiting);

  useEffect(() => {
    const joinAndStart = async () => {
      await meetingManager.startMeeting();
      await meetingManager.joinMeeting();
      await meetingManager.start();
    };
    joinAndStart();
  }, [meetingManager])

  useEffect(() => {
    if (talkingStatus == TalkingStatus.Talking || meetingStatus == MeetingStatus.Ended) {
      setIsModalVisible(false);
    } else if (meetingStatus == MeetingStatus.Ending) {
      setModalContents(modals.ending);
      setIsModalVisible(true);
    } else if (meetingStatus == MeetingStatus.EndingEnd) {
      switch(talkingStatus) {
        case TalkingStatus.Cancel:
          setModalContents(modals.cancel);
          setIsModalVisible(true);
          break;
        case TalkingStatus.Timeout:
          setModalContents(modals.timeout);
          setIsModalVisible(true);
          break;
        case TalkingStatus.NetworkError:
          setModalContents(modals.network_error);
          setIsModalVisible(true);
          break;
        case TalkingStatus.Error:
          setModalContents(modals.error);
          setIsModalVisible(true);
          break;
        case TalkingStatus.Left:
          setModalContents(modals.left);
          setIsModalVisible(true);
          break;
        case TalkingStatus.End:
          setModalContents(modals.end);
          setIsModalVisible(true);
          break;
          default:
          setIsModalVisible(false)
          break;
      }
    }
  }, [meetingStatus, talkingStatus])

  const onPressModalAction = useCallback(() => {
    switch(meetingStatus) {
      case MeetingStatus.Init:
      case MeetingStatus.Creating:
      case MeetingStatus.Created:
      case MeetingStatus.Started:
        meetingManager.end();
        break;
      default:
        meetingManager.updateMeetingStatus(MeetingStatus.Ended);
    }
  }, [meetingStatus, talkingStatus, meetingManager]);

  return (
    <SafeAreaView style={{
      height: '100%',
      position: 'relative'
    }}>
      <View style={{
        height: portrait? 'calc(100% - 70px)': '100%',
        position: 'relative'
      }}>
        <View style={{
          position: 'absolute',
          width: '100%',
          height: portrait ? '50%': '100%',
          left: 0,
          top: 0,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CustomerVideo portrait={portrait} />
        </View>
        <View style={{
          position: 'absolute',
          width: portrait ? '100%': '180px',
          height: '50%',
          right: portrait ? 'auto': '10px',
          top: portrait ? 'auto': '10px',
          bottom: portrait ? 0: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <LocalVideo portrait={portrait} />
        </View>
      </View>
      <View style={{ position: 'absolute', left: 0, right: 0, bottom: 10, display: 'flex', justifyContent: portrait ? 'center' : 'flex-start', flexDirection: 'row' }}>
        <ActionBar portrait={portrait}/>
      </View>
      <Modal
        visible={isModalVisible}
        animationType="none"
        transparent={false}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View>
              <View style={{ alignContent: 'center', alignItems: 'center' }}>
                { modalContents.messages.map(modalMessage => {return (<Text key={modalMessage.text}>{modalMessage.text}</Text>)})}
              </View>
              {(() => {
                if (modalContents.action) {
                  return <StyledButton
                    title={modalContents.action.text}
                    buttonStyle={{ marginTop: "24px", width: '15em' }}
                    color={'#DB0000'}
                    onPress={() => onPressModalAction()}
                  />
                }
              })()}
            </View>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  )
}
export default TalkingContainer;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    justifyContent: "center"
  },
});