export const TalkingStatus = {
  Init: "Init",
  Joining: "Joining",
  Joined: "Joined",
  Talking: "Talking",
  Left: "Left",
  End: "End",
  Cancel: "Cancel",
  Timeout: "Timeout",
  NetworkError: "NetworkError",
  Error: "Error"
} as const;
 
export type TalkingStatus = typeof TalkingStatus[keyof typeof TalkingStatus];