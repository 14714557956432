import { FC, memo } from "react"
import { useCustomerVideo } from "../../../providers/CustomerVideoProvider"
import { useMeetingManager } from "../../../providers/MeetingProvider"
import FaIcon from "../../FaIcon"
import StyledButton from "../../StyledButton"

type ActionBarProps = {
  portrait: boolean
}

const ActionBar: FC<ActionBarProps> = (props) => {
  const meetingManager = useMeetingManager();
  const { isVideoEnabled, toggleVideo } = useCustomerVideo();

  return (
    <>
      <StyledButton
        title="通話終了"
        color="#DB0000"
        onPress={() => {
          meetingManager.end();
        }}
        buttonStyle={props.portrait ? { width: '60%', marginBottom: '0px' } : { marginHorizontal: 20, width: 240 }} />

      <StyledButton
        title={isVideoEnabled ? "ビデオオフ(相手)" : "ビデオオン(相手)"}
        color="#6DBC89"
        icon={
          isVideoEnabled ?
            <FaIcon icon="video" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} /> :
            <FaIcon icon="video-slash" color='#fff' size={20} style={{ position: 'relative', top: '0px' }} />
        }
        onPress={toggleVideo}
        buttonStyle={{ width: 'wrapContent', display: props.portrait ? 'none' : 'block' }} />
    </>
  )
}

export default memo(ActionBar)